<script lang="ts">
	import '../app.css';
	import { _ } from 'svelte-i18n';
	import { locales } from '$lib/l10n';
	import { locale } from '$lib/i18n';
	import keycloak from '$lib/keycloak';
	import { onMount } from 'svelte';
	import { browser } from '$app/environment';
	import { Bars3, ChevronDown, ChevronUp, Icon, UserCircle, XMark } from 'svelte-hero-icons';
	import { authStore } from '$lib/stores/authStore';
	import Breadcrumbs from '$lib/ui/Breadcrumbs.svelte';
	import { Toaster } from 'svelte-french-toast';
	import { goto } from '$app/navigation';
	import CookieConsent from '$lib/ui/CookieConsent.svelte';

	let isAuthenticated = false;
	let username: string | null = null;
	let isAdmin = false;

	let tokenRefreshInterval: ReturnType<typeof setInterval>;
	let isMobileMenuOpen = false;
	let isUserMenuOpen = false;


	$: console.log('User Info:', { isAuthenticated, username, isAdmin });

	onMount(() => {
		if (browser && keycloak) {
			authStore.init();
			const unsubscribe = authStore.subscribe(state => {
				isAuthenticated = state.isAuthenticated;
				username = state.username;
				isAdmin = state.isAdmin;
			});

			if (isAuthenticated) {
				startTokenRefresh();
			}

			keycloak.onAuthSuccess = () => {
				authStore.init();
				startTokenRefresh();
			};

			keycloak.onAuthLogout = () => {
				authStore.logout();
				stopTokenRefresh();
			};

			return () => {
				unsubscribe();
				stopTokenRefresh();
			};
		}
	});

	async function login() {
		if (keycloak) {
			try {
				await keycloak.login({
					locale: $locale ?? 'en'
				});
				authStore.init();
				startTokenRefresh();
			} catch (error) {
				console.error('Failed to login', error);
			}
		}
	}

	async function logout() {
		if (keycloak) {
			try {
				await keycloak.logout();
				authStore.logout();
				stopTokenRefresh();
			} catch (error) {
				console.error('Failed to logout', error);
			}
		}
	}

	function startTokenRefresh() {
		tokenRefreshInterval = setInterval(async () => {
			if (keycloak) {
				try {
					await keycloak.updateToken(70);
					console.log('Token refreshed successfully');
					authStore.init(); // Update store after token refresh
				} catch (error) {
					console.error('Failed to refresh token', error);
				}
			}
		}, 60000);
	}

	function createAccount() {
		console.log('Create account clicked');
		closeMenus();
		goto(`/register`);
	}

	function stopTokenRefresh() {
		if (tokenRefreshInterval) {
			clearInterval(tokenRefreshInterval);
		}
	}

	function changeLocale(event: Event) {
		const target = event.target as HTMLSelectElement;
		locale.set(target.value);
	}

	function toggleMobileMenu() {
		isMobileMenuOpen = !isMobileMenuOpen;
	}

	function toggleUserMenu() {
		isUserMenuOpen = !isUserMenuOpen;
	}

	function closeMenus() {
		isMobileMenuOpen = false;
		isUserMenuOpen = false;
	}

	function handleMenuItemClick() {
		closeMenus();
	}

</script>

<div class="flex flex-col min-h-screen">
	<header class="bg-white shadow-md">
		<nav class="container mx-auto px-4 py-4">
			<div class="flex justify-between items-center">
				<a class="flex items-center space-x-2" href="/" on:click={handleMenuItemClick}>
					<span class="text-xl font-bold text-blue-600">{$_('app.name')}</span>
				</a>
				<div class="hidden md:flex items-center space-x-4">
					<select
						bind:value={$locale}
						class="px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 bg-gray-100 text-gray-700 hover:bg-gray-200"
						on:change={(event) => { changeLocale(event); closeMenus(); }}
					>
						{#each Object.entries(locales) as [key, value]}
							<option value={key}>{value}</option>
						{/each}
					</select>
					{#if isAuthenticated}
						<div class="relative">
							<button
								on:click={toggleUserMenu}
								class="flex items-center space-x-2 bg-gray-100 text-gray-700 px-3 py-2 rounded-md hover:bg-gray-200 transition duration-150 ease-in-out"
							>
								<Icon src={UserCircle} solid class="w-6 h-6 text-gray-500" />
								<span>{username}</span>
								<Icon src={isUserMenuOpen ? ChevronUp : ChevronDown} solid class="w-4 h-4 text-gray-500" />
							</button>
							{#if isUserMenuOpen}
								<div class="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
									{#if isAdmin}
										<a href="/admin" on:click={handleMenuItemClick} class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
											Admin Panel
										</a>
									{/if}
									<button
										on:click={() => { logout(); handleMenuItemClick(); }}
										class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
									>
										{$_('logout')}
									</button>
								</div>
							{/if}
						</div>
					{:else}
						<button on:click={() => { createAccount(); handleMenuItemClick(); }} class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-150 ease-in-out mr-2">
							{$_('create.account')}
						</button>
						<button on:click={() => { login(); handleMenuItemClick(); }} class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-150 ease-in-out">
							{$_('login')}
						</button>
					{/if}
				</div>
				<button class="md:hidden" on:click={toggleMobileMenu}>
					<Icon class="w-6 h-6" solid src={isMobileMenuOpen ? XMark : Bars3} />
				</button>
			</div>
			{#if isMobileMenuOpen}
				<div class="mt-4 md:hidden">
					<select
						bind:value={$locale}
						on:change={(event) => { changeLocale(event); closeMenus(); }}
						class="w-full px-3 py-2 mb-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 bg-gray-100 text-gray-700 hover:bg-gray-200"
					>
						{#each Object.entries(locales) as [key, value]}
							<option value={key}>{value}</option>
						{/each}
					</select>
					{#if isAuthenticated}
						<div class="bg-gray-100 rounded-md p-4 mb-2">
							<div class="flex items-center space-x-2 mb-2">
								<Icon src={UserCircle} solid class="w-6 h-6 text-gray-500" />
								<span class="font-medium">{username}</span>
							</div>
							{#if isAdmin}
								<a href="/admin" on:click={handleMenuItemClick} class="block w-full text-center bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mb-2">
									Admin Panel
								</a>
							{/if}
							<button on:click={() => { logout(); handleMenuItemClick(); }} class="w-full bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
								{$_('logout')}
							</button>
						</div>
					{:else}
						<button on:click={() => { createAccount(); handleMenuItemClick(); }} class="w-full bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mb-2">
							{$_('create.account')}
						</button>
						<button on:click={() => { login(); handleMenuItemClick(); }} class="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-2">
							{$_('login')}
						</button>
					{/if}
				</div>
			{/if}
		</nav>
	</header>

	{#key $locale}
		<Breadcrumbs />
	{/key}

	<main class="container mx-auto px-4 py-8 flex-grow">
		<slot />
	</main>

	<Toaster />

	<footer class="bg-gray-100 mt-auto">
		<div class="container mx-auto px-4 py-6">
			<div class="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
				<div class="text-sm text-gray-600 text-center md:text-left">
					© {new Date().getFullYear()} {$_('all.rights.reserved')}
					<br>
					{$_('author')}
				</div>
				<div class="flex flex-wrap justify-center md:justify-end space-x-4">
					<a class="text-blue-600 hover:underline" href="/about">{$_('about')}</a>
					<a class="text-blue-600 hover:underline" href="/privacy">{$_('privacy.policy')}</a>
					<a class="text-blue-600 hover:underline" href="/terms">{$_('terms.of.service')}</a>
				</div>
			</div>
		</div>
	</footer>

	<CookieConsent />
</div>

<style lang="postcss">
    :global(body) {
        @apply bg-gray-50;
    }
</style>