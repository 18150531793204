<script lang="ts">
	import { page } from '$app/stores';
	import { _ } from 'svelte-i18n';

	$: path = $page.url.pathname;
	$: segments = path.startsWith('/form') ? [] : path.split('/').filter(segment => segment !== '' && isNaN(Number(segment)));

	function getLabel(segment: string) {
		return $_(`breadcrumbs.${segment}`) || segment;
	}
</script>

<nav class="text-sm breadcrumbs px-4 py-2 bg-gray-100 rounded-md my-2">
	<ol class="list-none p-0 inline-flex">
		<li class="flex items-center">
			<a href="/" class="text-blue-600 hover:underline">{$_('breadcrumbs.home')}</a>
		</li>
		{#each segments as segment, i}
			<li class="flex items-center">
				<span class="mx-2 text-gray-500">/</span>
				<a href="/{segments.slice(0, i + 1).join('/')}" class="text-blue-600 hover:underline">
					{getLabel(segment)}
				</a>
			</li>
		{/each}
	</ol>
</nav>
