<script lang="ts">
	import { onMount } from 'svelte';
	import { fly } from 'svelte/transition';
	import { _ } from 'svelte-i18n';

	let showBanner = false;

	onMount(() => {
		const consentGiven = localStorage.getItem('cookieConsent');
		if (!consentGiven) {
			showBanner = true;
		}
	});

	function acceptCookies() {
		localStorage.setItem('cookieConsent', 'true');
		showBanner = false;
	}

</script>

{#if showBanner}
	<div
		transition:fly="{{ y: 100, duration: 500 }}"
		class="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 z-50"
	>
		<div class="container mx-auto flex justify-between items-center">
			<p class="mr-4">{$_('cookie.consent.message')}</p>
			<a href="/policy" class="text-blue-400 underline ml-4">{$_('cookie.consent.more_info')}</a>
			<div>
				<button
					on:click={acceptCookies}
					class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
				>
					{$_('cookie.accept')}
				</button>

			</div>
		</div>
	</div>
{/if}
